import React, { useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { ListGroup, Card, Container, Row } from 'react-bootstrap';
import getSlug from 'speakingurl';

import {
  DataTable,
  CreateButton,
  DeleteConfirmButton,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  SmallSpinner,
  useSetState,
} from '../shared/ReactToolbox';
import { use } from '../../redux/Factory';
import PageTypesDropdown from './PageTypesDropdown';
import PageStatusDropdown from './PageStatusDropdown';
import PageThumbnailModalButton from './PageThumbnailModalButton';
import { pageStatus } from './Page';

const PagesList = ({ type }) => {
  const [state, setState] = useSetState({
    showNewModal: false,
    propsArray: [],
    droppedItem: null,
    pageInEditModal: false,
  });
  const {
    getAllPages,
    getPagesList,
    pagesList,
    createPage,
    updatePage,
    movePage,
    deletePage,
  } = use.pages({ type });
  useCallback(() => getAllPages({ params: { type } }), [type]);

  if (getPagesList.isLoading || !pagesList) return <SmallSpinner />;
  
  const formFields = {
    title: {
      label: 'Title',
      onChange: (title, { title: prevTitle, slug: prevSlug }) => ({
        title,
        slug: prevSlug === getSlug(prevTitle) ? getSlug(title) : prevSlug,
      }),
    },
    description: {
      label: 'Description',
      formProps: { as: 'textarea' }
    },
    slug: { label: 'Slug' },
    type: {
      label: 'Page type',
      component: PageTypesDropdown,
    },
    status: {
      label: 'Status',
      component: PageStatusDropdown,
    },
  };

  return <>
    <Container style={{ marginTop: '50px' }}>
      <DataTable
        onClickRow={pageInEditModal => setState({ pageInEditModal })}
        showHeader={false}
        rowsPerPage={null}
        filterColumn='title'
        columns={[
          {
            orderBy: 'title',
            name: 'Title',
            selector: ({ slug, title }) => 
              <Link to={`pages/${type === null ? '' : `${type}/`}${slug}`}>{title}</Link>,
          },
          {
            name: 'Status',
            orderBy: ({ status }) => status,
            selector: ({ status }) => pageStatus[status],
          },
          {
            name: 'Actions',
            selector: page => <>
              <DeleteConfirmButton
                modalTitle="Delete page"
                onDelete={() => deletePage(page)}
                loading={deletePage.isLoading}
                className="float-end"
                disabled={page.status === 'published'}
              />
            </>
          },
        ]}
        data={Object.values(pagesList).sort(({ order: a }, { order: b }) => a > b)}
        onMove={({ item, target, reset }) => {
          movePage(
            item,
            {
              args: { 
                target,
                position: item.order > target.order ? 'right' : 'left',
              },
              callback: reset,
              onError: reset,
            }
          );
        }}
        orderByDefault='order'
      />
      
      <CreateButton onClick={() => setState({ showNewModal: true })} />
    </Container>

    {state.pageInEditModal &&
      <EditModal
        show={!!state.pageInEditModal}
        modalTitle="Modify page"
        loading={updatePage.isLoading}
        onHide={() => setState({ pageInEditModal: null })}
        initialState={state.pageInEditModal}
        formFields={{
          ...formFields,
          thumbnail: {
            label: 'Thumbnail',
            component: PageThumbnailModalButton,
          },
        }}

        onSave={page => updatePage(
          page,
          { original: state.pageInEditModal, callback: () => setState({ pageInEditModal: null }) }
        )}
    />}
    
    <CreateModal
      show={state.showNewModal}
      modalTitle="New page"
      loading={createPage.isLoading}
      onHide={() => setState({ showNewModal: false })}
      initialState={{ title: '', slug: '', type, status: 'draft' }}
      includeData={{ charts: [], tags: [], chart_indexes: [], thumbnail: '' }}
      formFields={formFields}

      onSave={newPage => createPage(
        newPage,
        { callback: () => setState({ showNewModal: false }) }
      )}
    />
  </>;
}

export default PagesList