import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { Row, Col, Navbar, Nav, Tabs, Tab } from 'react-bootstrap';
import _ from 'lodash';

import { use } from '../../redux/Factory';
import useLogin from '../../redux/actions/Login.js';

const Dashboard = () => {
  const { getChartsList } = use.charts();
  const { getAllPages } = use.pages();
  const { getTagsList } = use.tags();
  const { logout } = useLogin();
  const auth = useSelector(({ auth }) => auth);
  useEffect(() => {
    getChartsList();
    getAllPages();
    getTagsList();
  }, [])
  const { user } = auth;
  
  return (
    <>
      <Navbar bg='light'>
        <Link to="/">
          <Navbar.Brand>Dashboard</Navbar.Brand>
        </Link>
        <Nav className="mr-auto">
          <Navbar.Text id="dashboard-title">
            <div ></div>
          </Navbar.Text>
        </Nav>
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            User: <b>{user.username}</b>
          </Navbar.Text>
          <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </>
  );
}

export const DashboardTitle = ({ children }) => createPortal(children, document.getElementById('dashboard-title'));

export default Dashboard;
