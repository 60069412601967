import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Container, Button, Row, Col, Form } from 'react-bootstrap';
import axios from '../../utils/Axios';
import { useSetState } from '../shared/ReactToolbox';
import useLogin from '../../redux/actions/Login.js';


const Login = () => {
  const [state, setState] = useSetState({
    email: '',
    password: ''
  });
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated );
  const { login } = useLogin();
  
  const onChange = e => {
    setState({ [e.target.name]: e.target.value });
  };
  const onLoginClick = () => {
    const userData = {
      email: state.email,
      password: state.password
    };
    login(userData);
  };

  if (isAuthenticated) {
    return <Navigate to='/' />
  }
  return (
    <Container>
      <Row className="login">
        <Col md='2'>
        </Col>
        <Col md='8'>
          <h1>Login</h1>
          <Form>
            <Form.Group className="mb-3" controlId='emailId'>
              <Form.Label>Your Email</Form.Label>
              <Form.Control
                type='text'
                name='email'
                placeholder='Enter email'
                value={state.email}
                onChange={onChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId='passwordId'>
              <Form.Label>Your password</Form.Label>
              <Form.Control
                type='password'
                name='password'
                placeholder='Enter password'
                value={state.password}
                onChange={onChange}
              />
            </Form.Group>
          </Form>
          <Button variant='primary' onClick={onLoginClick}>
            Login
          </Button>
          <p className='mt-2'>
            Forget password?{' '}
            <a href={`${axios.defaults.baseURL}/accounts/password/reset/`}>Reset Password</a>
          </p>
        </Col>
        <Col md='2'>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
