import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import getSlug from 'speakingurl';

import {
  DataTable,
  CreateEditModal as CreateModal,
  CreateEditModal as EditModal,
  LoadingIndicator,
  CreateButton,
  DeleteConfirmButton,
} from '../shared/ReactToolbox';
import { use } from '../../redux/Factory';

const ChartsList = () => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [chartInEditModal, setChartInEditModal] = useState(null);

  const { chartsList, createChart, updateChart, deleteChart, getChartsList } = use.charts();
  useEffect(() => {
    getChartsList();
  }, []);

  if (!chartsList) return <LoadingIndicator />;
  
  const formFields = {
    name: {
      label: 'Name',
      onChange: (name, { name: prevName, slug: prevSlug }) => ({
        name,
        slug: prevSlug === getSlug(prevName) ? getSlug(name) : prevSlug,
      }),
    },
    slug: { label: 'Slug' },
  };

  return <>
    <Container style={{ marginTop: '50px' }}>
      
      <DataTable
        onClickRow={chartInEditModal => setChartInEditModal(chartInEditModal)}
        showHeader={false}
        rowsPerPage={null}
        filterColumn='name'
        columns={[
          {
            orderBy: 'name',
            name: 'Name',
            selector: ({ slug, name }) => 
              <Link to={`${slug}`}>{name}</Link>
          },
          {
            name: 'Actions',
            selector: chart => <>
              <DeleteConfirmButton
                modalTitle="Delete chart"
                onDelete={() => deleteChart(chart)}
                loading={deleteChart.isLoading}
                className="float-end"
              />
            </>
          },
        ]}
        data={Object.values(chartsList)}
        orderByDefault='name'
      />

      <CreateButton onClick={() => setShowNewModal(true)} />
    </Container>
    
    <CreateModal
      show={showNewModal}
      modalTitle="New chart"
      loading={createChart.isLoading}
      onHide={() => setShowNewModal(false)}
      initialState={{ name: '', slug: '' }}
      formFields={formFields}

      onSave={newChart => createChart(
        newChart,
        { callback: () => setShowNewModal(false) }
      )}
    />

    {chartInEditModal &&
      <EditModal
        modalTitle="Modify chart"
        loading={updateChart.isLoading}
        onHide={() => setChartInEditModal(null)}
        initialState={chartInEditModal}
        formFields={formFields}

        onSave={chart => updateChart(
          chart,
          {
            original: chartInEditModal,
            callback: () => setChartInEditModal(null),
          }
        )}
    />}
  </>;
};

export default ChartsList;