import { useState } from 'react';
import RichTextEditor from '../slate/RichTextEditor'
import { Container, Spinner, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { SmallSpinner, ErrorPage } from '../shared/ReactToolbox';
import { use } from '../../redux/Factory';
import { DashboardTitle } from '../dashboard/Dashboard';

export const pageTypes = {
  analysis: 'Analysis',
  null: 'Site',
};

export const pageStatus = {
  draft: 'Draft',
  published: 'Published',
  deleted: 'Deleted',
};

export default () => {
  const { type, slug } = useParams();
  const { getAllPages, updatePage, page } = use.pages({ type, slug });
  const { chartsList, getChartsList } = use.charts();
  
  if (getAllPages.isLoading || getChartsList.isLoading) return <SmallSpinner />;
  if (page === null) {
    return <ErrorPage>Page not found</ErrorPage>;
  }

  return page && <>
    <DashboardTitle>
      {updatePage.isLoading &&
        <Spinner
          animation="border"
          role="status"
          size="sm"
          className="float-end"
          style={{ marginLeft: '10px' }}
        />}
      {page && page.title}
    </DashboardTitle>
    <Container style={{ marginTop: '20px' }}>
      <h3>
        {chartsList && page.charts.map((slug, index) =>
          <Badge bg="light" className="chart" key={index}>
            <Link to={`/charts/${slug}`}>
              {chartsList[slug] ? chartsList[slug].name : <i>Not found: {slug}</i>}
            </Link>
          </Badge>
        )}
      </h3>
      <RichTextEditor
        initialValue={page.content}
        onSave={content => updatePage({ 
          ...page,
          content,
          charts: content
            .filter(({ type }) => type === 'plotly-chart')
            .map(({ slug }) => slug),
          chart_indexes: content
            .filter(({ type, slug }) => type === 'plotly-chart' && chartsList[slug])
            .map(({ slug, index }) => [slug, index]),
        })}
        loading={updatePage.isLoading}
      />
    </Container>
  </> || null;
};